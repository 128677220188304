/*

Boxer Template

http://www.templatemo.com/tm-446-boxer

*/

body
    {
        background: #fff;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        position: relative;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
    }
	
.tm-white a {
	color: #fff;
}

/* start h1 group */
h1 { 
    margin-top: 0;
    margin-bottom: 25px; 
}
h1,
h2,
h3,
h4
    {
        font-weight: bold;
    }
/* end h1 group */
p { line-height: 1.6em; }
/* img */
img
    {
        max-width: 100%;
        height: auto;
    }
/* img */

/* overlay */
.overlay 
    {
       width: 100%;
       height: 100%;
       background: rgba(0, 0, 0, 0.4);
       padding-top: 90px;
       padding-bottom: 90px;
    }  
/* over lay */

/* start page container */
@media (min-width: 768px) {
  .container {
    width: 700px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 900px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1000px;
  }
}
/* end page container */

/* start preloader */
.preloader
    {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row nowrap;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
        /*justify-content: center;*/
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        background: none repeat scroll 0 0 #fff;
    }
.sk-spinner-rotating-plane.sk-spinner 
    {
         width: 30px;
        height: 30px;
        background-color: #28a7e9;
         margin: 0 auto;
        -webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
                animation: sk-rotatePlane 1.2s infinite ease-in-out;
     }

@-webkit-keyframes sk-rotatePlane {
  0% {
         -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
                 transform: perspective(120px) rotateX(0deg) rotateY(0deg); }

  50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
                transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }

  100% {
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
                    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

@keyframes sk-rotatePlane {
  0% {
         -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
                 transform: perspective(120px) rotateX(0deg) rotateY(0deg); }

  50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
                transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }

  100% {
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
                    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }
/* end preloader */

/* start navigation */


/* start home */       
#home
    {
    background: url('../images/home-bg.jpg') no-repeat;
    background-size: cover;
    color: #fff;
    margin-top: 70px;
    text-align: center;
    width: 100%;
    }
#home p
    {
        font-weight: 400;
        font-style: italic;
        line-height: 2em;
    }
#home img
    {
        display: inline-block;
        margin-top: 30px;
    }
/* end home */

/* start divider */
#divider
    {
        text-align: center;
        padding-top: 80px;
        padding-bottom: 80px;
    }
#divider .fa
    {
        color: #28a7e9;
        font-size: 60px;
    }
#divider h3
    {
        font-size: 20px;
    }
/* end divider */

/* start feature */
#feature
    {
        background: #f8f8f8;
        padding-top: 80px;
        padding-bottom: 80px;
    }
#feature p
    {
        padding-top: 10px;
    }
#feature span
    {
        float: left;
    }
#feature .fa
    {
        background: #28a7e9;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 20px;
    }
/* end feature */

/* start feature1 */
#feature1
    {
        padding-top: 60px;
        padding-bottom: 60px;
    }
#feature1 p
    {
        padding-top: 10px;
    }
#feature1 span
    {
        float: left;
    }
#feature1 .fa
    {
        background: #28a7e9;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 20px;
    }
/* end feature1 */

/* start pricing */
#pricing
    {
        background: #f8f8f8;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 80px;
    }
#pricing h2
    {
        padding-bottom: 60px;
    }
#pricing .pricing
    {
        background: #fff;
    }
#pricing .active
    {
        position: relative;
        bottom: 20px;
    }
#pricing .pricing .pricing-title
    {
        background: #28a7e9;
        color: #fff;
        font-weight: bold;
        padding: 30px;
        border-radius: 8px;
    }
#pricing .pricing .pricing-title p
    {
        font-size: 20px;
    }
#pricing .pricing ul
    {
        padding: 0;
        margin: 0;
    }
#pricing .pricing ul li
    {
        display: block;
        list-style: none;
        padding: 16px;
    }
#pricing .pricing .btn
    {
        background: transparent;
        border: 1px solid #28a7e9;
        border-radius: 0px;
        color: #28a7e9;
        font-weight: bold;
        padding-right: 40px;
        padding-left: 40px;
        margin-top: 20px;
        margin-bottom: 30px;
        transition: all 0.3s ease;
    }
#pricing .pricing .btn:hover {
    background: #28a7e9;
    color: #fff;
}
/* end pricing */

/* start download */
#download
    {
        padding-top: 80px;
        padding-bottom: 80px;
    }
#download h2
    {
        padding-bottom: 20px;
    }
#download .btn
    {
        border-radius: 0px;
        font-weight: bold;
        margin-top: 20px;
        padding: 10px 40px 10px 40px;
    }
/* end download */

/* start contact */

#contact h2
    {
        padding-bottom: 10px;
    }
#contact address
    {
        padding-top: 20px;
    }
#contact address .fa
    {
        background: #28a7e9;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-top: 6px;
        margin-right: 10px;
    }
#contact .contact-form
    {
        padding-top: 40px;
    }
#contact .form-control
    {
        border: none;
        border-radius: 0px;
        box-shadow: none;
        margin-bottom: 20px;
    }
#contact input
    {
        height: 50px;
    }
#contact input[type="submit"]
    {
        background: #28a7e9;
        color: #fff;
        font-weight: bold;
        transition: all 0.3s ease;
    }
#contact input[type="submit"]:hover {
    background: #107DB6;
}
/* end contact */

/* start footer */
footer
    {
        background: #fff;
        font-weight: 400;
        text-align: center;
        padding: 20px;
    }
/* end footer */
.footer_item .logo {
    margin-bottom: 15px;
    width: 200px;
}
.logo {
    width: 100px; 
}
.services-icons {
    font-size: 6rem;
}
.list-inline.info a,.list-inline.social_icon a,nav.navbar.bootsnav ul.nav > li > a,.attr-nav > ul > li > a,.btn.know_btn,#filters > button,.portfolio_hover_area,.portfolio_hover_area .fa,.testimonial_content,.testimonial_content p:first-child::before,#contact_form .form-control,#contact_form .btn.submit_btn,footer a,.footer_social_icon .fa,.post .date,#scrollUp{
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
}
nav.navbar.bootsnav {
    background: #222533 none repeat scroll 0 0;
    border: 0 none;
}
nav.navbar.bootsnav ul.nav > li > a {
    color: #fff;
}
nav.navbar.bootsnav ul.nav > li > a:hover,nav.navbar.bootsnav ul.nav > li > a:focus {
    color: #aaa;
}
.navbar-toggler {
    background-color: #ffffff !important;
    color: #ffffff !important;
    border-radius: 16px !important;
}

.navbar-nav .nav-item a {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

:root {
    --bs-tertiary-bg-rgb: 34, 37, 51 !important; /* Cambiar a rojo (ejemplo) */
    --bs-bg-opacity: 1; /* Opacidad completa */
}
  
.icon-menu {
    color: #ffffff;
    font-size: 2.5rem;
}
.text-color {
    color: #fff !important;
}
a {
    color: inherit !important;  
    text-decoration: none !important; 
}
.nav-item {
    color: #ffffff;
}
.icon-contac-us {
    font-size: 3rem;
}
.text-contac-us {
    color: rgb(0, 0, 0) !important;
}
footer {
    background: url(../images/automatizacion-procesos.jpg)no-repeat;
    background-size:cover;
    position:relative;
    padding-top:45px;
}
footer::before {
    background: rgba(255, 255, 255, 0.33) none repeat scroll 0 0;
    content: "";
    height: 45px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
footer::after {
    background: rgba(34, 37, 51, 0.9) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.footer_top,.footer_bottom{ position:relative; z-index:1; color:#fff;}
.footer_item {
    margin-top: 75px;
}
.footer_item > h4 {
    color: #fff;
    font-family: "futura_ltbook",sans-serif;
    font-size: 25px;
    margin-bottom: 34px;
    text-transform: inherit;
}
.footer_item .list-unstyled > li a{
    color: #fff;
}
/* Footer About */
.footer_item .logo {
    margin-bottom: 15px;
    width: 200px;
}
.list-inline.footer_social_icon {
    margin: 32px 0 0;
}
.footer_social_icon .fa {
    background: #003dfa none repeat scroll 0 0;
    border-radius: 100%;
    color: #222;
    font-size: 20px;
    height: 45px;
    padding: 12px;
    text-align: center;
    width: 45px;
}
.footer_item .footer_social_icon .fa:hover,.footer_item .footer_social_icon .fa:focus {
    background: #d7a300 none repeat scroll 0 0;
}
/* Footer Explore */
.footer_menu .fa {
    font-size: 10px;
    margin-right: 18px;
}
.list-unstyled.footer_menu > li {
    padding: 4px 0;
}
/* Footer Post */
.list-unstyled.post,.list-unstyled.footer_contact {
    margin-top: -14px;
}
.post .date {
    border: 2px solid #fff;
    border-radius: 100%;
    display: block;
    float: left;
    font-size: 20px;
    height: 50px;
    line-height: 12px;
    margin-right: 15px;
    padding: 10px;
    text-align: center;
    width: 50px;
}
.footer_item li a:hover .date,.footer_item li a:focus .date{ border:2px solid #aaa; }
.footer_item li a:hover,.footer_item li a:focus{
    color: #aaa;
}
.icon-contact-us {
    font-size: 3rem;
}
.blur-image {
    filter: blur(5px); 
}

#testimonial {
    background-size:cover;
    position:relative;
}
#testimonial::before {
    background: #fff;
    opacity:0.7;
    content:"";
    position:absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
}
.testimonial_area{ position:relative;}
#testimonial h2 {
    margin-top: 63px;
}
.testimonial_item {
    padding: 0 15px;
}
.testimonial_content {
    background: #e5e5e5 none repeat scroll 0 0;
    border-radius: 18px;
    margin: 55px 0 30px;
    padding: 40px 15px 40px 70px;
    text-align: left;
    position:relative;
    color:#393939;
}
.testimonial_content::after {
    background: inherit;
    bottom: -10px;
    content: "";
    height: 20px;
    left: 47%;
    position: absolute;
    transform: rotate(45deg);
    width: 20px;
}
.testimonial_content p {
    position:relative;
}
.testimonial_content p:first-child::before {
    color: #989898;
    content: ",,";
    font-family: "GillSansMT-UltraBold";
    font-size: 60px;
    left: -54px;
    position: absolute;
    top: -24px;
    transform: rotateY(180deg);
}
#testimonial .col-sm-4:nth-child(3n+2) .testimonial_content {
    margin: 40px 0;
    padding-bottom: 70px;
}
.testimonial_item:hover .testimonial_content {
    background: #003dfa none repeat scroll 0 0;
}
.testimonial_item:hover .testimonial_content::after {
    width: 22px;
    height: 22px;
    bottom:-11px;
}
.testimonial_item:hover .testimonial_content {
    color: #fff;
}
.testimonial_item:hover .testimonial_content p:first-child::before {
    color: inherit;
}
.testimonial_item > img {
    border-radius: 100%;
    height: 80px;
    width: 80px;
}

.testimonials {
    width: 100px; 
    height: 100px; 
    border-radius: 50%; 
    overflow: hidden; 
    background: #000;
}

.testimonials img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    border-radius: 50%;
}


@media screen and ( max-width: 991px ) {
    .templatemo-box {
        margin-bottom: 30px;
    }
    #pricing .active {
        bottom: 0;
    }
    .pricing {
        margin-bottom: 20px;
    }
    #feature img, #download img {
        margin-top: 30px;
    }
    #feature1 img {
        margin-bottom: 30px;
    }
    nav.navbar.bootsnav .navbar-header {
        padding: 0;
    }
    nav.navbar.bootsnav .navbar-toggle {
        margin-top: 32px;
    }
    .attr-nav {
        right: 25px;
        top: 15px;
    }
    nav.navbar.bootsnav ul.nav > li > a {
        padding: 12px 0;
    }
}
@media screen and ( max-width: 767px ) {
    nav.navbar.bootsnav .navbar-toggle {
        margin-left: 15px;
    }
    .navbar-toggler {
        width: 26px;
    }
    #feature, #pricing, #download {
        padding-top: 20px;
        padding-bottom: 40px;
    }
    #contact .overlay {
        padding-top: 40px;
    }
    .logo {
        width: 60px; 
    }
    .navbar-toggler {
        width: 26px;
    }
}
/* start media 360 */
@media screen and ( max-width: 360px ){
    .pricing
        {
            padding-bottom: 40px;
        }
}
/* end media 360 */